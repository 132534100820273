import React from "react";
import Layout from "../components/layout"

const CambiosDevoluciones = () => {

    return (
        <Layout>
            <main className="row">
                <div className="col-12  m-auto py-5">
                    <h5 className="text-center">POLÍTICA DE CAMBIOS Y DEVOLUCIONES </h5>
                    <br/>
                    <div style={{textAlign:'justify'}}>
                        <p>A los <strong>Usuarios</strong>, les informamos que la siguiente Política de Cambios y Devoluciones les son aplicables por
                        el simple uso o acceso a cualquiera de las páginas, aplicaciones web y móviles, softwares y, aplicaciones
                        en general, que integran el <strong>Portal</strong>  de la <strong>Empresa</strong> a través de la siguiente dirección <a href="http://www.construshop.mx/" target="_blank" rel="noreferrer">www.construshop.mx</a> 
                        y por la adquisición de cualquier producto o servicio adquirido por el <strong>Usuario</strong> dentro del <strong>Portal</strong> , por lo que
                        entenderemos que los acepta y acuerda en obligarse a su cumplimiento. <strong>En el caso de que no esté de
                        acuerdo con la presente Política de Devolución deberá abstenerse de acceder o utilizar el Portal  y
                        adquirir cualquier producto o servicio dentro del Portal</strong>. </p>

                        <p>La satisfacción de nuestros <strong>Usuarios</strong> sobre nuestros productos y servicios es lo más importante para
                        nosotros, por lo que ponemos a tu disposición la siguiente Política de Cambios y Devoluciones.</p>

                        <br/>
                        <h5><u>DEVOLUCIONES</u></h5>
                        <br/>
                        <p>Se permiten devoluciones única y exclusivamente en caso de que se haya firmado la remisión de entrega 
                        y requieran dicha devolución en el plazo prefijado por la <strong>Empresa</strong>. </p>

                        <br/>
                        <h5><u>PROCESO DE DEVOLUCIÓN </u></h5>
                        <br/>
                        <h5>1. TIEMPO PARA LA DEVOLUCIÓN </h5>
                        <br/>
                        <p>Deberá  solicitar la devolución de un producto adquirido al correo <a href="mailto:inaki.lopez@construshop.mx">inaki.lopez@construshop.mx</a>  dentro de
                        las 24 horas siguientes a la fecha en la que recibió sus productos y firmo su remisión de entrega. </p>
                        <br/>
                        <h5>2. CONDICIONES PARA LA DEVOLUCIÓN </h5>
                        <br/>
                        <ol type="a">
                            <li> Cualquier producto o servicio que quiera ser devuelto deberá de encontrarse en perfectas
                            condiciones y sin daños evidentes por el mal uso por parte del <strong>Usuario</strong>.</li>
                            <li> El o los productos deberán de encontrarse en las mismas condiciones en las que fueron entregados
                            al <strong>Usuario</strong> y, en la medida de lo posible, encontrarse con el empaque y etiquetado original que
                            garantice la devolución en perfecto estado. </li>
                            <li> Deberás de presentar tu ticket de compra o captura de pantalla del correo electrónico de
                            confirmación de tu compra en dónde sea visible el monto pagado y los productos o servicios 
                            adquiridos.</li>
                            <li> La devolución se hará directamente con el proveedor del producto, y el <strong>Portal</strong> fungirá como asesor
                            en el proceso. </li>
                        </ol>
                        <br/>
                        <h5>3. FORMA DE LA DEVOLUCIÓN </h5>
                        <br/>
                        <p>La devolución debe de ser solicitada vía correo electrónico a la dirección <a href="mailto:inaki.lopez@construshop.mx">inaki.lopez@construshop.mx</a>  y
                        recibirás la información de envío para hacer la devolución, los gastos de envío corren por cuenta del <strong>Usuario</strong>. </p>

                        <br/>
                        <h5>4. REEMBOLSO </h5>
                        <br/>
                        <p>El reembolso derivado de la devolución del producto o servicio será realizado al cliente por medio de
                        transferencia electrónica de fondos.</p>
                        <p>Los reembolsos realizados a tarjetas de crédito o débito son exclusivamente para aquellas realizadas con
                        esa misma tarjeta. Los tiempos de reembolso directo a tarjetas de crédito o débito, dependerá del
                        procedimiento interno de cada una de las instituciones financieras.</p>
                        <p><strong><u>*Los gastos de envío corren por cuenta del Usuario</u></strong></p>

                        <br/>
                        <h5><u>MODIFICACIONES</u></h5>
                        <br/>
                        
                        <p>La presente Política de Cambios y Devoluciones pueden ser modificadas, en cualquier momento sin previo
                        aviso y/o consentimiento del <strong>Usuario</strong>. En consecuencia, el <strong>Usuario</strong> debe leer atentamente las Políticas de 
                        Cambios y Devoluciones cada vez que pretenda adquirir productos y servicios del <strong>Portal</strong>. </p>
                        
                        <br/>
                        <h5><u>LEYES APLICABLES </u></h5>
                        <br/>
                        <p>Para la interpretación, cumplimiento y ejecución de los presentes Políticas de Cambios y Devoluciones, el <strong>Usuario</strong> está de acuerdo en que serán aplicables las leyes Federales de los Estados Unidos Mexicanos y
                        competentes los tribunales que para tal efecto designe la <strong>Empresa</strong>, renunciando expresamente a cualquier
                        otro fuero o jurisdicción que pudiera corresponderles debido a sus domicilios presentes o futuros o por
                        cualquier otra causa. </p>

                        <br/>
                        <h5><u>CONTACTO </u></h5>
                        <br/>

                        <p>El <strong>Usuario</strong> puede contactar, en todo momento, al personal de la <strong>Empresa</strong> para cualquier aclaración,
                        comentario, duda y/o sugerencia relacionada con los Servicios y Contenidos, con el <strong>Portal</strong>  y/o con las
                        presentes Políticas de Cambios y Devoluciones en <a href="mailto:inaki.lopez@construshop.mx">inaki.lopez@construshop.mx</a></p>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default CambiosDevoluciones

export const Head = () => <title>Pol&iacute;tica de cambios y devoluciones</title>